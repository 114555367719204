import { AxiosRequestConfig } from 'axios'
import { endpoints, fetcher, poster } from 'src/utils/axios'
import { CustomError } from 'src/api/utils'
import logger from 'src/utils/logger'
import useSWR from 'swr'
import {
  AdminConsoleResponseDto,
  AdminConsoleUserDto,
} from '../../../models/admin_console_user/AdminConsoleUserInterface'

// ----------------------------------------------------------------------

interface UseCurrentAdminConsoleUserResult {
  username: string
  error: string
  isLoading: boolean
  isValidating: boolean
  refreshAdminConsoleUser: Function
}

export async function authenticate(key: string): Promise<AdminConsoleResponseDto> {
  try {
    const URL = endpoints.admin.authenticate
    const config: AxiosRequestConfig = { headers: { key } }

    const response = (await poster([URL, {}], config)) as AdminConsoleResponseDto

    return response
  } catch (error) {
    logger.warn('admin console authenticate error', {}, error as Error)
    return { success: false, errorMessage: 'Sorry, this is a private venue' }
  }
}

export async function endSession(): Promise<AdminConsoleResponseDto> {
  try {
    const URL = endpoints.admin.endSession

    const response = (await poster([URL, {}])) as AdminConsoleResponseDto

    return response
  } catch (error) {
    logger.error('admin console end session error', {}, error as Error)
    return { success: false, errorMessage: "We couldn't log you out, post in #bugs" }
  }
}

export function useCurrentAdminConsoleUser(): UseCurrentAdminConsoleUserResult {
  const URL = endpoints.admin.currentUser

  const { data, error, isLoading, isValidating, mutate } = useSWR<AdminConsoleUserDto, CustomError>([URL, {}])

  return {
    username: data?.username as string,
    error: error?.error as string,
    isLoading,
    isValidating,
    refreshAdminConsoleUser: mutate,
  }
}

export async function sendCommand(command: string): Promise<AdminConsoleResponseDto> {
  try {
    const URL = endpoints.admin.command
    const params = { command }

    const response = (await poster([URL, params])) as AdminConsoleResponseDto

    return response
  } catch (error) {
    logger.warn('admin console command error', { command }, error as Error)
    return { success: false, errorMessage: 'Failed to send command' }
  }
}
