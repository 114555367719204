'use client'

import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { useFetchEnvironmentVariables } from 'src/api/utils'

const StripeContext = createContext<{
  stripePromise: Promise<Stripe | null> | null
} | null>(null)

type Props = {
  children: React.ReactNode
}

export function StripeProvider({ children }: Props): React.ReactNode {
  const { publicVariables } = useFetchEnvironmentVariables()
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)

  useEffect(() => {
    if (publicVariables) {
      const stripe = loadStripe(publicVariables.STRIPE_PUBLISHABLEKEY)
      setStripePromise(stripe)
    }
  }, [publicVariables])

  const memoizedValue = useMemo(() => ({ stripePromise }), [stripePromise])

  return <StripeContext.Provider value={memoizedValue}>{children}</StripeContext.Provider>
}

export function useStripePromise(): Promise<Stripe | null> | null {
  const context = useContext(StripeContext)

  if (!context) {
    throw new Error('useStripePromise must be used within a StripeProvider')
  }

  return context.stripePromise
}
