'use client'

import { createContext, useContext, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useCurrentOrganization } from 'src/api/organization'
import { useAuthContext } from 'src/auth/hooks'
import IdleTimer from 'src/components/idle-timer-modal'
import { PublicOrganizationDocument } from '../../../models/organization/OrganizationInterface'

// ----------------------------------------------------------------------

interface IdleTimerContextType {
  isPrompted: boolean
  remainingTime: number
  handleStillHere: () => void
}

interface IdleTimerConfig {
  timeout: number
  promptBeforeIdle: number
  onPrompt: () => void
  onIdle: () => void
  onActive: () => void
  disabled: boolean
  debounce: number
}

const DEFAULT_TIMEOUT = 25 // 25 minutes total
const DEFAULT_PROMPT = 30 // Show warning at 40 seconds

const getTimerConfig = (organization: PublicOrganizationDocument | undefined): IdleTimerConfig => {
  const config = organization?.idleTimerConfig
  const isEnabled = config?.enabled ?? false

  return {
    timeout: 1000 * 60 * (config?.timeoutMinutes ?? DEFAULT_TIMEOUT),
    promptBeforeIdle: 1000 * (config?.promptBeforeIdleSeconds ?? DEFAULT_PROMPT),
    onPrompt: () => {},
    onIdle: () => {},
    onActive: () => {},
    disabled: !isEnabled,
    debounce: 500,
  }
}

const IdleTimerContext = createContext({} as IdleTimerContextType)

export const useIdleTimerContext = (): IdleTimerContextType => useContext(IdleTimerContext)

export function IdleTimerProvider({ children }: { children: React.ReactNode }): React.ReactNode {
  const [isPrompted, setIsPrompted] = useState(false)
  const [remainingTime, setRemainingTime] = useState(0)

  const { currentOrganization } = useCurrentOrganization()
  const { logout } = useAuthContext()

  const handlePrompt = (): void => {
    setIsPrompted(true)
  }

  const handleIdle = (): void => {
    setIsPrompted(false)
    logout()
  }

  const handleActive = (): void => {
    setIsPrompted(false)
  }

  const config: IdleTimerConfig = {
    ...getTimerConfig(currentOrganization),
    onPrompt: handlePrompt,
    onIdle: handleIdle,
    onActive: handleActive,
    debounce: 500,
  }

  const { getRemainingTime, activate } = useIdleTimer(config)

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted) {
        setRemainingTime(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isPrompted, getRemainingTime])

  return (
    <IdleTimerContext.Provider value={{ isPrompted, remainingTime, handleStillHere: handlePrompt }}>
      {children}
      {isPrompted && (
        <IdleTimer
          remainingTime={remainingTime}
          onStayActive={() => {
            activate()
            setIsPrompted(false)
          }}
        />
      )}
    </IdleTimerContext.Provider>
  )
}
