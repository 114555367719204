'use client'

import React, { useEffect } from 'react'
// components
//
import { usePathname, useRouter } from 'src/routes/hooks'
import { paths } from 'src/routes/paths'
import loading from 'src/app/loading'
import { Authorization_JWT_Key } from 'src/utils/axios'
import { Constants } from '../../../../../utils/constants'
import { AuthContext } from './auth-context'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

type PathValue = string | { [key: string]: PathValue }

export function AuthConsumer({ children }: Props): React.ReactNode {
  const router = useRouter()
  const currentPath = usePathname()
  const auth = React.useContext(AuthContext)
  const flattenPaths = (obj: { [key: string]: PathValue }): string[] =>
    Object.values(obj).reduce<string[]>(
      (acc, val) => acc.concat(typeof val === 'object' && val !== null ? flattenPaths(val) : (val as string)),
      []
    )

  const authPaths = flattenPaths(paths.auth)
  // Perform side effects in useEffect rather than during render
  useEffect(() => {
    if (!auth.isLoading && !auth.authenticated && auth.method === Constants.JWT_LOGIN_METHOD) {
      // This means JWT token is there so wait for the user to be authenticated
      if (localStorage.getItem(Authorization_JWT_Key)) {
        return
      }

      router.replace(paths.auth.tab.login)
      return
    }

    if (!auth.isLoading && !auth.authenticated && !authPaths.includes(currentPath)) {
      router.replace(paths.auth.root)
    }
  }, [auth.isLoading, auth.authenticated, currentPath, router, authPaths, auth.method])

  if (!auth.isLoading) {
    return children
  }
  return loading()
}
