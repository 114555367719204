import { forwardRef } from 'react'
// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

// ----------------------------------------------------------------------

export interface IdleTimerProps {
  remainingTime: number
  onStayActive: () => void
  open?: boolean
  sx?: object
}

const IdleTimerModal = forwardRef<HTMLDivElement, IdleTimerProps>(
  ({ remainingTime, onStayActive, open = true, sx, ...other }, ref) => (
    <Dialog ref={ref} maxWidth="sm" open={open} sx={sx} {...other}>
      <DialogTitle>Are you still there?</DialogTitle>

      <DialogContent>
        <Typography>You&apos;ll be logged out due to inactivity in {remainingTime} seconds</Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="primary" onClick={onStayActive}>
          I&apos;m Still Here
        </Button>
      </DialogActions>
    </Dialog>
  )
)

IdleTimerModal.displayName = 'IdleTimerModal'
export default IdleTimerModal
