import useSWR, { KeyedMutator } from 'swr'
import { CustomError } from 'src/api/utils'
import { endpoints, fetcher, poster } from 'src/utils/axios'
import { swrOpts } from 'src/providers/swr'
import logger from 'src/utils/logger'
import { PublicOrganizationDocument } from '../../../models/organization/OrganizationInterface'

// ----------------------------------------------------------------------

interface UseCurrentOrganizationResult {
  currentOrganization: PublicOrganizationDocument | undefined
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  mutateCurrentOrganization: KeyedMutator<PublicOrganizationDocument>
}

export function useCurrentOrganization(): UseCurrentOrganizationResult {
  const URL = endpoints.organization.get

  // we have to pass the fetcher and opts in here because useCurrentOrganization gets called
  // outside of the SWRProvider (in AuthProvider)
  const { data, error, isLoading, isValidating, mutate } = useSWR<PublicOrganizationDocument, CustomError>(
    [URL, {}],
    fetcher,
    swrOpts
  )

  const response = {
    currentOrganization: data,
    isLoading,
    isValidating,
    isError: !!error,
    mutateCurrentOrganization: mutate,
  }

  return response
}

export async function sendApplicationConsentPermissionEmailToAdmin({
  organizationId,
  adminEmail,
  permissionUrl,
  firstName,
  lastName,
}: {
  organizationId: string
  adminEmail: string
  permissionUrl: string
  firstName: string
  lastName: string
}): Promise<boolean> {
  try {
    const URL = endpoints.organization.application_permission_email_to_admin

    const response = (await poster([
      URL,
      { organizationId, adminEmail, permissionUrl, firstName, lastName },
    ])) as boolean

    return response
  } catch (error) {
    logger.error(
      'error in sendApplicationConsentPermissionEmailToAdmin',
      { organizationId, adminEmail, permissionUrl, firstName, lastName },
      error as Error
    )
    return false
  }
}
