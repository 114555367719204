'use client'

import { useCallback, useMemo } from 'react'
import { useCurrentUser } from 'src/api/user'
import { AuthorizationContextType } from 'src/auth/types'
import { usePermissionInfo } from 'src/api/permission'
import { useCurrentOrganization } from 'src/api/organization'
import { AuthorizationContext } from './authorization-context'
import {
  AccountPermissionInfo,
  AudiencePermissionInfo,
  OrganizationPermissionInfo,
  ProgramPermissionInfo,
} from '../../../../../models/permission/PermissionInterface'
import { Constants } from '../../../../../utils/constants'

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function AuthorizationProvider({ children }: Props): React.ReactNode {
  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser()
  const { currentOrganization, isLoading: isOrganizationLoading } = useCurrentOrganization()
  const { permissionRecord, isLoading, permissionMutator } = usePermissionInfo()

  const isAuthorizationLoading = useCallback(
    (): boolean => isCurrentUserLoading || isOrganizationLoading || isLoading,
    [isCurrentUserLoading, isLoading, isOrganizationLoading]
  )

  const reload = useCallback(async (): Promise<void> => {
    await permissionMutator()
  }, [permissionMutator])

  const getProgramAccountPermission = useCallback(
    (programId: string): AccountPermissionInfo => {
      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo
      return permissionRecord[programPermission.accountId as string] as AccountPermissionInfo
    },
    [permissionRecord]
  )

  const isAuthorizedToAccessProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.admin || programPermission.owner || programPermission.member) {
        return true
      }

      const accountPermission: AccountPermissionInfo = getProgramAccountPermission(programId) as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.admin || accountPermission.owner
    },
    [getProgramAccountPermission, permissionRecord]
  )

  const isAuthorizedToManageProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.admin || programPermission.owner) {
        return true
      }

      const accountPermission: AccountPermissionInfo = getProgramAccountPermission(programId) as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.owner || accountPermission.admin
    },
    [getProgramAccountPermission, permissionRecord]
  )

  const isProgramOwner = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.owner) {
        return true
      }

      const accountPermission: AccountPermissionInfo = getProgramAccountPermission(programId) as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.owner || accountPermission.admin
    },
    [getProgramAccountPermission, permissionRecord]
  )

  // We might not need this function as this does not reflect Similar Program Type while calculating the totalActivePrograms
  const canCreateProgramOnOrgCurrentPlan = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[
      currentUser?.organizationId
    ] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    let totalActivePrograms = 0
    orgPermission.programIds.forEach((programId) => {
      const prgPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (prgPermission.status === Constants.STATUS_ACTIVE) {
        totalActivePrograms += 1
      }
    })
    return orgPermission.onPaidPlan || (!orgPermission.onPaidPlan && totalActivePrograms < 2)
  }, [currentUser?.organizationId, permissionRecord])

  const canActivateProgramOnOrgFreePlan = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const orgPermission: OrganizationPermissionInfo = permissionRecord[
        currentUser?.organizationId
      ] as OrganizationPermissionInfo

      if (!orgPermission) {
        return false
      }

      const currentProgramPermission = permissionRecord[programId] as ProgramPermissionInfo
      if (!currentProgramPermission) {
        return false
      }

      const currentProgramType = currentProgramPermission.programType

      let totalActiveSimilarPrograms = 0
      orgPermission.programIds.forEach((pId) => {
        const prgPermission: ProgramPermissionInfo = permissionRecord[pId] as ProgramPermissionInfo

        if (prgPermission?.status === Constants.STATUS_ACTIVE && prgPermission?.programType === currentProgramType) {
          totalActiveSimilarPrograms += 1
        }
      })

      // If similar program exists, then we can't create a new program
      return totalActiveSimilarPrograms === 0
    },
    [currentUser?.organizationId, permissionRecord]
  )

  const isOrgOnPaidPlan = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgPermission: OrganizationPermissionInfo = permissionRecord[
      currentUser?.organizationId
    ] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    return orgPermission.onPaidPlan
  }, [currentUser?.organizationId, permissionRecord])

  const wasProgramCreatedFromAPremiumTemplate = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      return programPermission.paidPlan
    },
    [permissionRecord]
  )

  const isParticipantOfProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      return programPermission.member
    },
    [permissionRecord]
  )

  const isProgramAccountAvailable = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission) {
        return false
      }

      if (programPermission.accountId) {
        return true
      }

      return false
    },
    [permissionRecord]
  )

  const isPaidProgram = useCallback(
    (programId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const programPermission: ProgramPermissionInfo = permissionRecord[programId] as ProgramPermissionInfo

      if (!programPermission || !programPermission.accountId) {
        return false
      }

      const accountPermission: AccountPermissionInfo = getProgramAccountPermission(programId) as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.paidPlan
    },
    [getProgramAccountPermission, permissionRecord]
  )

  const isAuthorizedToManageAccount = useCallback(
    (accountId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const accountPermission: AccountPermissionInfo = permissionRecord[accountId] as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.owner || accountPermission.admin
    },
    [permissionRecord]
  )

  const isAccountOwner = useCallback(
    (accountId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const accountPermission: AccountPermissionInfo = permissionRecord[accountId] as AccountPermissionInfo

      if (!accountPermission) {
        return false
      }

      return accountPermission.owner
    },
    [permissionRecord]
  )

  const isAudienceOwner = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      return audiencePermission.owner
    },
    [permissionRecord]
  )

  const isAuthorizedToManageAudience = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      return audiencePermission.owner || audiencePermission.admin
    },
    [permissionRecord]
  )

  const isAuthorizedToUseAudienceOnProgram = useCallback(
    (audienceId: string): boolean => {
      if (!permissionRecord) {
        return false
      }

      const audiencePermission: AudiencePermissionInfo = permissionRecord[audienceId] as AudiencePermissionInfo

      if (!audiencePermission) {
        return false
      }

      const { adminUseOnly, owner: isUserOwner, admin: isUserAdmin } = audiencePermission

      if (adminUseOnly && !isUserOwner && !isUserAdmin) {
        return false
      }

      return true
    },
    [permissionRecord]
  )

  const isAdminOfAnyProgram = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgId = currentUser?.organizationId
    const orgPermission = permissionRecord[orgId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    const programs = orgPermission.programIds

    // eslint-disable-next-line no-restricted-syntax
    for (const programId of programs) {
      const programPermission = permissionRecord[programId] as ProgramPermissionInfo
      if (programPermission && (programPermission.admin || programPermission.owner)) {
        return true
      }
    }

    return false
  }, [permissionRecord, currentUser?.organizationId])

  const isParticipantOfAnyProgram = useCallback((): boolean => {
    if (!permissionRecord) {
      return false
    }

    const orgId = currentUser?.organizationId
    const orgPermission = permissionRecord[orgId] as OrganizationPermissionInfo

    if (!orgPermission) {
      return false
    }

    const programs = orgPermission.programIds

    // eslint-disable-next-line no-restricted-syntax
    for (const programId of programs) {
      const programPermission = permissionRecord[programId] as ProgramPermissionInfo

      if (programPermission && programPermission.member) {
        return true
      }
    }

    return false
  }, [permissionRecord, currentUser?.organizationId])

  const isAuthorizedToHaveBillingTab = useCallback((): boolean => {
    // This function checks if the billing page is disabled for non billing admins

    if (currentOrganization && !currentOrganization?.isBillingPageDisabled) {
      return true
    }
    if (!permissionRecord) {
      return false
    }

    const orgId = currentUser?.organizationId
    const orgPermission = permissionRecord[orgId] as OrganizationPermissionInfo
    if (!orgPermission) {
      return false
    }

    const { accountIds } = orgPermission
    // eslint-disable-next-line no-restricted-syntax
    for (const accountId of accountIds) {
      const accountPermission = permissionRecord[accountId] as ProgramPermissionInfo
      if (accountPermission.admin || accountPermission.owner) {
        return true
      }
    }

    return false
  }, [permissionRecord, currentUser?.organizationId, currentOrganization])

  const memoizedValue = useMemo(
    (): AuthorizationContextType => ({
      permissionRecord,
      isAuthorizationLoading,

      isAuthorizedToAccessProgram,
      isAuthorizedToManageProgram,
      canCreateProgramOnOrgCurrentPlan,
      canActivateProgramOnOrgFreePlan,
      isParticipantOfProgram,
      isOrgOnPaidPlan,
      wasProgramCreatedFromAPremiumTemplate,
      isPaidProgram,
      isProgramOwner,
      isAccountOwner,
      isProgramAccountAvailable,
      isAuthorizedToManageAccount,
      isAuthorizedToHaveBillingTab,
      isAudienceOwner,
      isAuthorizedToManageAudience,
      isAuthorizedToUseAudienceOnProgram,
      isAdminOfAnyProgram,
      isParticipantOfAnyProgram,
      reload,
    }),
    [
      permissionRecord,
      isAuthorizationLoading,
      isAuthorizedToAccessProgram,
      isAuthorizedToManageProgram,
      canCreateProgramOnOrgCurrentPlan,
      canActivateProgramOnOrgFreePlan,
      isParticipantOfProgram,
      isOrgOnPaidPlan,
      wasProgramCreatedFromAPremiumTemplate,
      isPaidProgram,
      isProgramOwner,
      isAccountOwner,
      isProgramAccountAvailable,
      isAuthorizedToManageAccount,
      isAuthorizedToHaveBillingTab,
      isAudienceOwner,
      isAuthorizedToManageAudience,
      isAuthorizedToUseAudienceOnProgram,
      isAdminOfAnyProgram,
      isParticipantOfAnyProgram,
      reload,
    ]
  )

  return <AuthorizationContext.Provider value={memoizedValue}>{children}</AuthorizationContext.Provider>
}
