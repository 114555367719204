'use client'

import React, { useState, useEffect, FormEvent } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Alert from '@mui/material/Alert'
import { DialogTitle } from '@mui/material'
import { authenticate, endSession, sendCommand, useCurrentAdminConsoleUser } from 'src/api/admin-console'
import { useAuthContext } from 'src/auth/hooks'
import { clearCrispContext } from 'integrations/crisp'

export default function AdminConsole(): React.ReactElement {
  const [open, setOpen] = useState(false)
  const [keyValue, setKeyValue] = useState('')
  const [commandValue, setCommandValue] = useState('')
  const [response, setResponse] = useState<string | undefined>('')
  const [errorResponse, setErrorResponse] = useState<string | undefined>('')
  const { authenticated: loggedInAsNormalUser } = useAuthContext()
  const { username: currentAdminUser, refreshAdminConsoleUser } = useCurrentAdminConsoleUser()

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
    setKeyValue('')
  }

  const handlePasswordSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const resp = await authenticate(keyValue)
    refreshAdminConsoleUser()

    if (resp.success === true) {
      setResponse(resp.message)
      setErrorResponse(undefined)
    } else {
      setErrorResponse(resp.errorMessage)
      setResponse(undefined)
    }
  }

  const handleCommandSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const resp = await sendCommand(commandValue)

    if (resp.success === true) {
      setResponse(resp.message)
      setErrorResponse(undefined)
      clearCrispContext()
    } else {
      setErrorResponse(resp.errorMessage)
      setResponse(undefined)
    }
  }

  const handleSessionEndClick = async (): Promise<void> => {
    const resp = await endSession()
    refreshAdminConsoleUser()

    if (resp.success === true) {
      setResponse(resp.message)
      setErrorResponse(undefined)
      clearCrispContext()
    } else {
      setErrorResponse(resp.errorMessage)
      setResponse(undefined)
    }
  }

  // Setting up the keydown event listener
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void | Function => {
      if (event.ctrlKey && event.shiftKey && event.key === 'C') {
        handleClickOpen()
      }
    }
    // Add event listener
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  function dialogTitle(): React.ReactElement {
    if (!currentAdminUser) {
      return <DialogTitle>What can I get you? ☕️</DialogTitle>
    }
    return <DialogTitle>Welcome back, {currentAdminUser} 🥳</DialogTitle>
  }

  function messageBox(): React.ReactElement {
    if (response) {
      return <Alert severity="info">{response}</Alert>
    }
    if (errorResponse) {
      return <Alert severity="error">{errorResponse}</Alert>
    }
    return <></>
  }

  function loginForm(): React.ReactElement {
    if (currentAdminUser) {
      return <></>
    }

    return (
      <form onSubmit={handlePasswordSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="key"
            type="password"
            fullWidth
            variant="outlined"
            value={keyValue}
            onChange={(e) => {
              setKeyValue(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Coffee</Button>
        </DialogActions>
      </form>
    )
  }

  function commandForm(): React.ReactElement {
    if (!currentAdminUser) {
      return <></>
    }

    return (
      <form onSubmit={handleCommandSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="command"
            type="text"
            fullWidth
            variant="outlined"
            value={commandValue}
            onChange={(e) => {
              setCommandValue(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => handleSessionEndClick()} color="error">
            End session
          </Button>
          <div style={{ flexGrow: 1 }} /> {/* Spacer element */}
          <Button type="submit">Order</Button>
        </DialogActions>
      </form>
    )
  }

  // Don't show the admin console before you log in
  if (!loggedInAsNormalUser) {
    return <></>
  }

  return (
    <>
      {currentAdminUser && (
        <Alert
          severity="warning"
          variant="filled"
          action={
            <Button color="inherit" size="small" onClick={handleSessionEndClick}>
              End Session
            </Button>
          }
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            zIndex: 99999,
            marginTop: -3,
          }}
        >
          Warning, you are impersonating.
        </Alert>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        {dialogTitle()}
        {messageBox()}
        {loginForm()}
        {commandForm()}
      </Dialog>
    </>
  )
}
